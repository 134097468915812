import React from 'react';
import Main from '../containers/Layout';
import ecommerceStoreContent from '../../content/pages/ecommerce-store.yml';
import EcommerceStoreContainer from '@/containers/toolkit/EcommerceStoreContainer';

export interface EcommerceStorePageContent {
  viewport1: {
    title1: string;
    title2: string;
    description: string;
    button: {
      label: string;
      url: string;
    };
  };
  viewport2: {
    cards: {
      title: string;
      tickedDescription: { item: string }[];
      image: string;
    }[];
  };
  viewport3: {
    title: string;
    leftColumn: { description1: string }[];
    rightColumn: { description1: string }[];
    bottomText: string;
    button: { label: string; url: string };
  };
}

const EcommerceStore: React.FunctionComponent = () => {
  const { viewport1, viewport2, viewport3 } =
    ecommerceStoreContent as unknown as EcommerceStorePageContent;
  return (
    <Main>
      <EcommerceStoreContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
        }}
      />
    </Main>
  );
};

export default EcommerceStore;
